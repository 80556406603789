.footer {
    background-color: #1d1d2b; /* Dark navy blue */
    color: #f1f1f1; /* Light gray/white text */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    font-family: Arial, sans-serif;
  }
  
  .footer-section {
    flex: 1 1 200px;
    margin: 20px 10px;
  }
  
  .footer h4 {
    color: #b19cd9; /* Light purple */
    position: relative; /* Required for the pseudo-element positioning */
    padding-bottom: 5px;
    margin-bottom: 15px;
    display: inline-block;
    cursor: pointer;
}

.footer h4::after {
    content: '';
    display: block;
    width: 0; /* Start with no underline */
    height: 2px; /* Thickness of the line */
    background-color: #b19cd9; /* Light purple for the underline */
    transition: width 0.3s ease-in-out; /* Animation effect */
    position: absolute;
    bottom: 0; /* Position it below the text */
    left: 0;
}

.footer h4:hover::after {
    width: 100%; /* Line expands to full width on hover */
    background-color: #a06cd5; /* Darker purple for hover effect */
}

  
  .footer p, .footer a {
    color: #f1f1f1;
    text-decoration: none;
  }
  .social-icons a {
    color: #b19cd9;
    font-size: 24px;
    margin: 0 8px;
    transition: color 0.3s ease; /* Only transition color on hover */
  }
  
  .social-icons a:hover {
    color: #a06cd5; /* Darker purple on hover */
    animation: rotate 0.6s infinite alternate; /* Continuous rotation effect */
  }
  




 .footer-button {
    background-color: #b19cd9;
    color: #1d1d2b;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .footer-button:hover {
    background-color: #a06cd5; /* Darker purple */
  }
  
  .footer-chat .chat-button {
    background-color: #00bfff; /* Blue for chat button */
    color: #f1f1f1;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .footer-chat .chat-button:hover {
    background-color: #0099cc;
  }
  
  .footer-bottom {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #333;
    padding-top: 10px;
    color: #999;
  }
  .chat-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .chat-content {
    background-color: #1d1d2b;
    color: #f1f1f1;
    padding: 20px;
    width: 300px;
    border-radius: 10px;
  }
  
  .close-chat {
    background: transparent;
    border: none;
    color: #f1f1f1;
    font-size: 20px;
    cursor: pointer;
    float: right;
  }
    