.carousel-container {
    padding: 0;
    background: transparent;
    margin-top: 1px;
  }
  
  .carousel-image {
    height: 600px;
    object-fit: cover;
    filter: brightness(85%);
  }
  
  .carousel-caption h3 {
    color: #FFFFFF;
    font-size: 2.2rem;
    font-weight: bold;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  }
  
  .carousel-caption p {
    color: white;
    font-size: 1.2rem;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  }
  
  .carousel-indicators li {
    background-color: #7559c5;
  }
  
  .carousel-indicators .active {
    background-color: #432c7a;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .carousel-image {
      height: 400px; /* Reduce image height for medium screens */
    }
  
    .carousel-caption h3 {
      font-size: 1.8rem; /* Smaller font for medium screens */
    }
  
    .carousel-caption p {
      font-size: 1rem; /* Smaller text for medium screens */
    }
  }
  
  @media (max-width: 576px) {
    .carousel-image {
      height: 250px; /* Reduce image height for small screens */
    }
  
    .carousel-caption h3 {
      font-size: 1.5rem; /* Smaller font for small screens */
    }
  
    .carousel-caption p {
      font-size: 0.9rem; /* Smaller text for small screens */
    }
  }
  