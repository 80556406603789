/* Full-width background for center content */
.center-background {
    width: 100%;
    background-color: #f8f8f2; /* Off-white background */
    display: flex;
    justify-content: center;
    padding: 10px 0;

}

.center-content {
    text-align: center;
}

.center-icon {
    width: 150px;
    height: 150px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.center-icon:hover {
    transform: scale(1.1);
    opacity: 0.9;
}

.company-name {
    font-family: Georgia, 'Times New Roman', Times, serif ;
    font-size: 2rem;
    color: #333333;
    background: linear-gradient(90deg, #6c63ff, #492366, #5d5da3);
    -webkit-background-clip: text;
    color: transparent;
    transition: color 0.4s ease;
    margin-top: 0.5rem;
}

.company-name:hover {
    color: #6c63ff;
    text-shadow: 0px 0px 10px rgba(108, 99, 255, 0.8);
}

.team-page-title {
    font-size: 2.5em;
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-top: 20px;
}

.team-page-text {
    color: black;
    margin-bottom: 10px;
}
.director-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center-align the cards */
    gap: 1.5rem;
    width: 100%;
    max-width: 1200px;
    padding-top: 3px;
    padding-bottom: 3rem;
    margin: 0 auto; /* Center within the container */
}


/* Card Styles */

/* Card Styles */
.director-card {
    width: 380px; /* Increased width */
    height: 350px; /* Increased height */
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
    background-color: white;
}

.director-card:hover {
    transform: scale(1.05);
}

/* Image Styles */
.director-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Hover Info Overlay */
.director-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);/* Semi-transparent navy */
    color: white;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    transition: opacity 0.3s;
}

.director-card:hover .director-info {
    opacity: 1;
}

/* Text Styling */
.director-info h2 {
    font-size: 1.3em; /* Slightly reduced */
    color: #FFD700; /* Gold text for the name */
    margin-bottom: 8px;
}

.director-info h3 {
    font-size: 1em; /* Reduced font size */
    color: #B0C4DE; /* Light steel blue */
    margin-bottom: 8px;
}

.director-info p {
    font-size: 0.9em; /* Smaller font for description */
    color: white;
    text-align: center;
    line-height: 1.2em; /* Adjust line height */
}
